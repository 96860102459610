interface GpcResponse {
  error: boolean;
  show_gpc?: boolean;
  message?: string;
}

interface NavigatorGPC extends Navigator {
  globalPrivacyControl?: boolean;
}

const hostname =
  window.location.hostname === 'blog.maxmind.com' ||
  window.location.hostname === 'dev.maxmind.com' ||
  window.location.hostname === 'support.maxmind.com'
    ? 'www.maxmind.com'
    : window.location.hostname;

const gpcCheck = async (signal: boolean): Promise<boolean> => {
  let showGpc: boolean;

  if (window.location.pathname.startsWith('/en/opt-out')) {
    showGpc = false;
  } else {
    try {
      const destinationUrl = new URL(
        `${window.location.protocol}//${hostname}/en/gpc-check`,
      );
      destinationUrl.searchParams.append('js_signal', signal ? '1' : '0');
      const response = await fetch(destinationUrl, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      const gpcBody: GpcResponse = await response.json();
      showGpc = gpcBody.show_gpc ?? false;
    } catch (error) {
      showGpc = false;
    }
  }
  return showGpc;
};

// This works around the behavior of safari in private mode
const getHasLocalStorage = (): boolean => {
  try {
    const testString = '_mm-ls-test';
    window.localStorage.setItem(testString, testString);
    const testGet = window.localStorage.getItem(testString);
    window.localStorage.removeItem(testString);
    return testGet === testString;
  } catch (error) {
    return false;
  }
};

const hasLocalStorage = getHasLocalStorage();

const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;

const gpcTimestampKey = '_mmgpctime';

const styles = `
dialog#global-privacy-control-check::backdrop { background-color: rgba(0,0,0,0.75); }
dialog#global-privacy-control-check {
    border-color: rgba(0, 0, 0, 0.175);
    border-radius: 7px;
    border-width: 1px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    color: #364956;
    max-width: 95%;
    padding: 0px;
}
p.gpc-modal-p {
    padding: 16px;
    padding-bottom: 0px;
}
div.gpc-modal-header {
    align-items: flex-end;
    background-color: rgb(248, 249, 250);
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
    display: flex;
    justify-content: space-between;
    padding: 16px;
}
div.gpc-modal-header h4 {
    margin: 0;
}
div.gpc-modal-header button {
    border: 1px solid rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    font-size: 1.2rem;
    padding: 1px 12px;
    transition: box-shadow, background-color 0.15s ease;
}
div.gpc-modal-header button:active,
div.gpc-modal-header button:hover {
    background-color: rgba(0,0,0,0.15);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
}
div.gpc-modal-footer {
    background-color: rgb(248, 249, 250);
    border-top: 1px solid rgba(0, 0, 0, 0.175);
    display: flex;
    justify-content: flex-end;
    padding: 6px 12px;
}
div.gpc-modal-footer button {
    background: #008fc6;
    border-radius: 0;
    border: 1px solid #008fc6;
    color: #fdfbdc;
    margin: 0.5rem;
    padding: 0.375rem 1.125rem;
    transition: color, background-color 0.15s ease;
}
div.gpc-modal-footer button:active {
    background-color: #0a58ca;
    border-color: #0a53be;
    color: #fff;
}
div.gpc-modal-footer button:focus, div.gpc-modal-footer button:hover {
    background-color: #006a93;
    border-color: #006a93;
    color: #fdfbdc;
}
@media screen and (min-width: 530px) {
    dialog#global-privacy-control-check {
        max-width: 500px;
    }
}
`;

const modalHtml = `
<div class="gpc-modal-header">
    <h4>Global Privacy Control</h4>
    <button class="btn-close" type="button" aria-label="Close"></button>
</div>
<p class="gpc-modal-p">MaxMind has detected a GPC signal from your browser. MaxMind does not share any data collected from browsers with the GPC signal with any other companies (except with our service providers or as required by law).</p>
<p class="gpc-modal-p">If you would like to opt out of other sharing of your personal data please see our <a class="js-donotsell" href="${window.location.protocol}//${hostname}/en/opt-out" rel="noreferrer noopener" target="_blank">Do Not Sell Webform</a>.</p>
<div class="gpc-modal-footer">
    <button type="button">Close</button>
</div>`;

// returns true if modal has been dismissed in the past 30 days
const hasRecentlyDismissedModal = (): boolean => {
  if (!hasLocalStorage) return false;
  const dismissalTime = Number(window.localStorage.getItem(gpcTimestampKey));
  return Date.now() - dismissalTime < oneMonthInMilliseconds;
};

const storeModalDismissal = () => {
  if (!hasLocalStorage) return;
  window.localStorage.setItem(gpcTimestampKey, Date.now().toString());
};

const runGpc = async () => {
  const showGpc = await gpcCheck(
    (navigator as NavigatorGPC).globalPrivacyControl ?? false,
  );
  if (!showGpc || hasRecentlyDismissedModal()) return;
  // create modal
  const modal = document.createElement('dialog');
  modal.id = 'global-privacy-control-check';
  modal.innerHTML = modalHtml;
  // creates styles
  const style = document.createElement('style');
  style.innerHTML = styles;
  // add to DOM
  document.body.appendChild(style);
  document.body.appendChild(modal);
  // register event listeners
  const buttons = modal.querySelectorAll('button');
  buttons.forEach((button) =>
    button.addEventListener('click', () => {
      modal.close();
    }),
  );
  const doNotSellAnchor = document.querySelector('a.js-donotsell');
  if (doNotSellAnchor)
    doNotSellAnchor.addEventListener('click', () => {
      modal.close();
    });
  modal.addEventListener('close', () => {
    storeModalDismissal();
  });
  // prevent ESC-ing out of modal
  modal.addEventListener('cancel', (e) => e.preventDefault());
  modal.showModal();
};

runGpc();
